<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="GENERAL_SETTINGS"
      tooltip-title="GENERAL_SETTINGS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
    <div
      class="bg-white min-h-screen md:mt-5 pt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div v-if="isLoading" class="mt-28">
        <Loader :content="true" />
      </div>
      <div v-else>
        <div
          class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
        >
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3">
            <UiMultiSelect
              v-model="generalSettings.allowed_timezones"
              :options="timeZoneList"
              title="ALLOWED_TIMEZONE"
              :already-selected="permittedTimeZones"
              label="title"
              rules="required"
              class="w-full"
              :search-enable="true"
              :is-locked-from-upper-level="generalSettings.is_locked_up_allowed_timezones"
              :lock-value="generalSettings.is_locked_allowed_timezones"
              :disabled="
                generalSettings.is_locked_up_allowed_timezones ||
                generalSettings.is_locked_allowed_timezones
              "
              :show-lock="true"
              @updateLockValue="
                (e) => {
                  updateLockValue(e, 'allowed_timezones')
                }
              "
              @receiveList="
                updatedPermittedLists(generalSettings.allowed_timezones, permittedTimeZones)
              "
            />
          </div>
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3">
            <UiSingleSelect
              v-model="generalSettings.default_timezone"
              title="DEFAULT_TIMEZONE"
              label="title"
              :options="permittedTimeZones"
              class="flex-1"
              reduce="timeDifference"
              :is-locked-from-upper-level="generalSettings.is_locked_up_default_timezone"
              :lock-value="generalSettings.is_locked_default_timezone"
              :show-lock="true"
              :disabled="
                generalSettings.is_locked_up_default_timezone ||
                generalSettings.is_locked_default_timezone
              "
              @updateLockValue="
                (e) => {
                  updateLockValue(e, 'default_timezone')
                }
              "
            />
          </div>
        </div>
        <div
          class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
        >
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3">
            <UiMultiSelect
              v-model="generalSettings.allowed_currencies"
              :options="currenciesList"
              :already-selected="permittedCurrencies"
              title="ALLOWED_CURRENCIES"
              label="title"
              class="w-full"
              rules="required"
              :lock-value="generalSettings.is_locked_allowed_currencies"
              :is-locked-from-upper-level="generalSettings.is_locked_up_allowed_currencies"
              :disabled="
                generalSettings.is_locked_allowed_currencies ||
                generalSettings.is_locked_up_allowed_currencies
              "
              :show-lock="true"
              @updateLockValue="
                (e) => {
                  updateLockValue(e, 'allowed_currencies')
                }
              "
              @receiveList="
                updatedPermittedLists(generalSettings.allowed_currencies, permittedCurrencies)
              "
            />
          </div>
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3">
            <UiSingleSelect
              v-model="generalSettings.default_currency"
              label="title"
              reduce="id"
              :options="permittedCurrencies"
              title="DEFAULT_CURRENCY"
              :is-locked-from-upper-level="generalSettings.is_locked_up_default_currency"
              :lock-value="generalSettings.is_locked_default_currency"
              :disabled="
                generalSettings.is_locked_up_default_currency ||
                generalSettings.is_locked_default_currency
              "
              class="flex-1"
              :show-lock="true"
              @updateLockValue="
                (e) => {
                  updateLockValue(e, 'default_currency')
                }
              "
            />
          </div>
        </div>
        <div
          class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
        >
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3">
            <UiMultiSelect
              v-model="generalSettings.allowed_countries"
              :options="countriesList"
              title="ALLOWED_COUNTRIES"
              :already-selected="permittedCountries"
              label="title"
              :lock-value="generalSettings.is_locked_allowed_countries"
              :is-locked-from-upper-level="generalSettings.is_locked_up_allowed_countries"
              :disabled="
                generalSettings.is_locked_allowed_countries ||
                generalSettings.is_locked_up_allowed_countries
              "
              class="w-full"
              :show-lock="true"
              @updateLockValue="
                (e) => {
                  updateLockValue(e, 'allowed_countries')
                }
              "
              @receiveList="
                updatedPermittedLists(generalSettings.allowed_countries, permittedCountries)
              "
            />
          </div>
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3">
            <UiSingleSelect
              v-model="generalSettings.default_country"
              :options="permittedCountries"
              title="DEFAULT_COUNTRY"
              class="flex-1"
              label="title"
              rules="required"
              reduce="id"
              :lock-value="generalSettings.is_locked_default_country"
              :is-locked-from-upper-level="generalSettings.is_locked_up_default_country"
              :disabled="
                generalSettings.is_locked_default_country ||
                generalSettings.is_locked_up_default_country
              "
              :show-lock="true"
              @updateLockValue="
                (e) => {
                  updateLockValue(e, 'default_country')
                }
              "
            />
          </div>
        </div>

        <div class="pb-5 md:pb-0">
          <button
            class="mb-5 mt-5 md:mt-10 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
            :disabled="isLoading"
            @click.prevent="handleSubmit(updateSettings(invalid))"
          >
            {{ $t('dashboard.Save') }}
          </button>
        </div>
      </div>
    </div>
    <!-- </ValidationObserver> -->
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapState, mapActions } from 'vuex'
import { objectDeepCopy, getCountriesList } from '@src/utils/generalUtil'
import { getTimeZoneList } from '@utils/moment.util'
import Loader from '@components/BaseComponent/Loader.vue'

export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    UiMultiSelect,
    // ValidationObserver,
    Loader,
  },
  page: {
    title: 'System Settings | Site Settings',
    meta: [
      {
        name: 'description',
        content: 'Site Settings',
      },
    ],
  },
  data() {
    return {
      timeZoneList: getTimeZoneList(),
      countriesList: getCountriesList(),
      permittedTimeZones: [],
      permittedCountries: [],
      permittedCurrencies: [],
      generalSettings: null,
      currenciesList: null,
      isLoading: false,
      forceRender: 0,
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getSettings()
      },
    },
  },
  created() {
    this.generalSettings = objectDeepCopy(this.$store.state.layout.generalSettings)
    this.currenciesList = objectDeepCopy(
      this.$store.state.settings.siteSettingsDefaults.currenciesList,
    )
  },
  mounted() {
    if (this.currentCampusScope) this.getSettings()
    else this.setSettingsDefaultData()
  },
  methods: {
    updatedPermittedLists(allowedList = [], permittedList = []) {
      allowedList.forEach((allowItem) => {
        let isExist = permittedList.some((permittedItem) => {
          return permittedItem.id === allowItem.id
        })
        if (!isExist && typeof allowItem === 'object') permittedList.push(allowItem)
      })
    },
    updateSettings(invalid) {
      if (invalid) return
      this.transformPayload().then(() => {
        this.updateFeaturesGeneralSettings(this.generalSettings)
          .then((res) => {
            this.generalSettings = res
            this.$store.commit('layout/SET_GENERAL_SETTINGS', res)
            this.$store.commit('toast/NEW', {
              message: 'Settings Update Successfully',
              type: 'success',
            })
          })
          .finally(() => {
            this.getSettings()
          })
      })
    },

    getSettings() {
      this.isLoading = true
      this.getFeaturesGeneralSettings().then((res) => {
        this.generalSettings = objectDeepCopy(res)
        this.$store.commit('layout/SET_GENERAL_SETTINGS', res)
        this.setSettingsDefaultData()
        this.isLoading = false
      })
    },
    transformPayload() {
      return new Promise((resolve, reject) => {
        this.reduceAllowedItems('allowed_timezones', 'timeDifference')
        this.reduceAllowedItems('allowed_currencies')
        this.reduceAllowedItems('allowed_countries')
        resolve()
      })
    },
    reduceAllowedItems(allowedList = '', key = 'id') {
      this.generalSettings[allowedList] = this.generalSettings[allowedList].map((item) => {
        return item[key]
      })
    },
    updateLockValue(e, key) {
      this.generalSettings[`is_locked_${key}`] = e
    },
    setSettingsDefaultData() {
      this.permittedTimeZones = []
      this.permittedCurrencies = []
      this.permittedCountries = []
      this.permittedTimeZones = this.timeZoneList.filter((zone) => {
        return this.generalSettings.allowed_timezones.includes(zone.timeDifference)
      })
      this.permittedCurrencies = this.currenciesList.filter((currenncy) => {
        return this.generalSettings.allowed_currencies.includes(currenncy.id)
      })
      this.permittedCountries = this.countriesList.filter((country) => {
        return this.generalSettings.allowed_countries.includes(country.id)
      })
    },
    ...mapActions('settings', ['updateFeaturesGeneralSettings', 'getFeaturesGeneralSettings']),
  },
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 17px;
  height: 16px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-green);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
